
import {computed, defineComponent, onMounted, ref} from "vue";
import Entities from "@/components/base/common/Entities.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {ISearchField} from "@/core/entity/ISearchField";
import {OPERATOR, PAYMENT_METHOD, PAYMENT_STATUS, PAYMENT_SUB_TYPE} from "@/core/config/Constant";
import {CompanySelect, HandleState, LoadPanel, SearchEntityEvent, TagSelect} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import PaymentStatus from "@/views/payment/PaymentStatusLabel.vue";
import PaymentMethod from "@/views/payment/PaymentMethod.vue";
import PaymentTypeLabel from "@/views/payment/PaymentTypeLabel.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import PaymentService from "@/core/services/PaymentService";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import {ModelAddress} from "@/core/entity/IAddress";
import {ILocation} from "@/core/entity/ILocation";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {Field} from "vee-validate";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import PaymentCards from "@/views/payment/PaymentCards.vue";
import PaymentCard from "@/views/payment/PaymentCard.vue";
import FormAddress from "@/components/base/address/FormAddress.vue";
import FormCurrency from "@/components/base/currency/FormCurrency.vue";

export default defineComponent({
  name: "Search",
  components: {
    FormCurrency,
    FormAddress,
    PaymentCard,
    PaymentCards,
    DateTimeSelect,
    Field,
    BaseRadio,
    BaseForm,
    BaseModal,
    QuickAction,
    CurrencyFormat,
    PaymentTypeLabel, PaymentMethod, PaymentStatus, KtDatatable, BaseSelect, Entities
  },
  setup() {
    const user = computed(() => store.getters.currentUser);
    const model = ref<any>({payments: []})
    const state = ref<any>({id: '', payment: {address: new ModelAddress()}, submitting: false})
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Payments Search',
        [
          {link: false, router: '', text: 'Search'}
        ]
      )
    })
    const fields = ['all']
    const fieldSearch: ISearchField[] = [
      {column: 'clientId', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Client'},
      {column: 'policyNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Policy #'},
      {column: 'certificate', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Certificate #'},
      {column: 'claimNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Claim #'},
      {column: 'invoiceNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Invoice #'},
      {column: 'method', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Payment Method'},
      {column: 'number', type: 'TEXT', operator: OPERATOR.EQ, value: '', name: 'Number'},
      {column: 'paymentName', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Pay To'},
      {column: 'subType', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Type'},
      {column: 'status', type: 'SELECT', operator: OPERATOR.EQ, value: 'PENDING', name: 'Status'},
      {column: 'parentId', type: 'HIDDEN', operator: OPERATOR.NUL, value: 'p', name: 'Status'},
      {column: 'group', type: 'HIDDEN', operator: OPERATOR.EQ, value: 'false', name: 'Status'},
      {column: 'tags', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Tag'},
    ]

    const tableHeader = ref([
      {
        name: "Claim#",
        key: "claimNumber",
        sortable: true,
      },
      {
        name: "Invoice#",
        key: "invoiceNumber",
        sortable: true,
      },
      {
        name: "Pay To",
        key: "paymentName",
        sortable: true,
      },
      /*
            {
              name: "Address",
              key: "address",
              sortable: true,
            },
      */
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Type",
        key: "subType",
        sortable: true,
      },
      {
        name: "Method",
        key: "method",
        sortable: true,
      },
      {
        name: "Amount",
        key: "amount",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },

    ]);
    const paymentSearchPage = computed(() => store.state.PaymentModule.paymentSearchPage)
    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const states = computed<ILocation[]>(() => store.state.LocationModule.states)
    const currencies = computed(() => store.state.ExchangeRateModule.currencies);
    return {
      user,
      ...SearchEntityEvent(Actions.SEARCH_PAYMENT, fieldSearch, fields, [], 'paymentView'),
      paymentSearchPage,
      ...CompanySelect(),
      tableHeader,
      ...LoadPanel(),
      model,
      state,
      ...HandleState(),
      countries,
      states,
      currencies,
      PAYMENT_SUB_TYPE,
      PAYMENT_STATUS,
      PAYMENT_METHOD,
      ...TagSelect('PAYMENT')
    }
  },
  methods: {
    onAdd(item: any) {
      this.model.payments.push(item)
    },
    onRemove(item) {
      this.model.payments = this.model.payments.filter(i => i !== item)
    },
    onGroup() {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      PaymentService.createGroup(this.model).then(() => {
        this.paginationLoad()
      }).finally(() => {
        Swal.close();
        this.model.payments = [];
      })
    },
    onEdit(payment) {
      this.state.id = payment.id;
      this.state.payment = {
        type: payment.type,
        status: payment.status,
        subType: payment.subType,
        name: payment.name,
        address: payment.address ? payment.address : new ModelAddress(),
        amount: payment.amount,
        currency: payment.currency,
        method: payment.method ? payment.method : 'NA',
        amountInvCurrency: payment.amountInvCurrency,
        number: payment.number,
        paidDt: payment.paidDt,
        paidThrough : payment.paidThrough
      }
      const base = this.$refs.paymentSearchEditRef as typeof BaseModal;
      base.showBaseModal();
    },
    submit() {
      this.state.submitting = true;
      PaymentService.update(this.state.id, this.state.payment).then(() => {
        this.paginationLoad()
        const base = this.$refs.paymentSearchEditRef as typeof BaseModal;
        base.hideBaseModal();
      }).finally(() => {
        this.state.submitting = false;
      })
    },
    onStateNameSelect(stateName) {
      const name = this.states.find((item) => item.code === stateName)?.name
      if (name && this?.state && this.state?.payment && this?.state?.payment?.address) {
        this.state.payment.address.stateName = name
      }
    },
    onCountryNameSelect(countryName) {
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name && this?.state?.payment && this?.state?.payment?.address) this.state.payment.address.countryName = name
    },
    onCancel() {
      const base = this.$refs.paymentSearchEditRef as typeof BaseModal;
      base.showBaseModal();
    },

    onVoid(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: " Please confirm voiding the payment!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PaymentService.delete(id).then(() => {
            this.paginationLoad();
          })
        }
      })
    },
    deletePayment(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PaymentService.delete(id).then(() => {
            this.paginationLoad();
          })
        }
      })
    }
  }
})
