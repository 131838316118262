<template>
  <template v-if="type ==='LINK'">
    <template v-if="access">
      <router-link :to="to" :class="[{'menu-link px-3': isItem}, clazz]"  >
        {{title}}
      </router-link>
    </template>
    <template v-else>
        <span class="text-secondary" :class="[{'menu-link px-3': isItem}]" >
          {{title}}
        </span>
    </template>
  </template>
  <template v-else>
    <template v-if="access">
        <span class="cursor-pointer  " @click="onClick" :class="[{'menu-link px-3' : isItem}, clazz]" >
          {{title}}
        </span>
    </template>
    <template v-else>
      <span class="text-secondary" :class="[{'px-3 menu-link': isItem}]">
        {{title}}
      </span>
    </template>
  </template>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "MenuLink",
  props: {
    clazz: {default:''},
    isItem: {default: true},
    type: {type: String, default: 'CLICK'},
    title: {type: String},
    access: {type: Boolean, default: true},
    to: {type: String, default: ''},
  },
  emits: ['click'],
  setup(_,{emit}) {
    const onClick = () => {
      emit('click');
    }
    return {
      onClick
    }
  }
})
</script>

<style scoped>

</style>