
import {defineComponent, PropType, ref, watch} from "vue";
import Pagination from "@/components/base/paging/Pagination.vue";
import PaymentService from "@/core/services/PaymentService";

export default defineComponent({
  name: "PaymentCards",
  components: {Pagination},
  props: {
    cardData: {type: Array as PropType<any>, required: true},
    currentPage: {type: Number, default: 1},
    total: {type: Number, default: 0},
    rowsPerPage: {type: Number, default: 10},
  },
  emits: ['current-change'],
  setup(props, {emit}) {

    const payments = ref<any>([])
    const load = () => {
      const ids = props.cardData.map(val => val.paymentId);
      if (ids.length > 0) {
        PaymentService.list({paymentIds: ids}).then(res => {
          payments.value = res;
        })
      } else {
        payments.value = [];
      }
    }
    load();
    const paginationChange = (val) => {
      emit('current-change', val)
    }
    watch(() => props.cardData, () => {
      load();
    })
    return {
      payments,
      paginationChange
    }
  }
})
