
import {computed, defineComponent, ref, watch} from 'vue'
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {ILocation} from "@/core/entity/ILocation";
import store from "@/store";
import {HandleState} from "@/core/composite/composite";
import {ModelAddress} from "@/core/entity/IAddress";
import AddressValidateService from "@/core/services/AddressValidateService";
import {Field} from "vee-validate";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "FormAddress",
  components: {BaseSelect, FormErrorMessage, Field},
  props: {
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const formatterAddress = ref({validate: false, formatterAddress: '', correct: true, address: new ModelAddress()})
    const model = computed<any>({
      get()  {

        return props.modelValue
      },
      set(model) {
        emit('update:modelValue', model)
      }
    })
    watch(()=> props.modelValue, (cb: any)=>{
      formatterAddress.value.validate = false;
      formatterAddress.value.formatterAddress = '';
      formatterAddress.value.address = new ModelAddress();
      if (cb && cb?.countryCode) {
        store.dispatch(Actions.LOAD_STATE, cb?.countryCode)
      }
    })
    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const states = computed<ILocation[]>(() => store.state.LocationModule.states)

    return {
      formatterAddress,
      states,
      countries,
      model,
      ...HandleState(),
    }
  },
  methods:{
    onCountryNameSelect(code) {
      const name = this.countries.find((item) => item.code === code)?.name
      if (name ) this.model.countryName = name
    },
    onStateNameSelect(code) {
      const name = this.states.find((item) => item.code === code)?.name
      if (name ) {
        this.model.stateName = name
      }
    },
    validateAddress(address) {
      AddressValidateService.create(address).then((res: any) => {
        this.formatterAddress.validate = true;
        this.formatterAddress.formatterAddress = res.result?.address.formattedAddress;
        const postalAddress = res.result?.address.postalAddress;
        const line = postalAddress.addressLines[0];
        const city = postalAddress.locality;
        const state = postalAddress.administrativeArea;
        const country = postalAddress.regionCode
        const zip = postalAddress.postalCode
        this.formatterAddress.address.street = line;
        this.formatterAddress.address.cityName = city;
        this.formatterAddress.address.stateCode = state;
        this.formatterAddress.address.countryCode = country;
        this.formatterAddress.address.zip = zip;
      })
    },
    correctAddress() {
      this.model.street = this.formatterAddress.address.street
      this.model.cityName = this.formatterAddress.address.cityName
      this.model.stateCode = this.formatterAddress.address.stateCode
      this.model.countryCode = this.formatterAddress.address.countryCode
      this.model.zip = this.formatterAddress.address.zip
      this.onCountryNameSelect(this.model.countryCode)
      this.onCountrySelectedEvent(this.model.countryCode)
      this.onStateNameSelect(this.model.stateCode)
      this.formatterAddress.validate = false;
      this.formatterAddress.address = new ModelAddress();
    }
  }

})
