import { vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card card-rounded" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = {
  key: 0,
  class: "form-check form-check-custom"
}
const _hoisted_5 = ["id"]
const _hoisted_6 = ["for"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = { class: "menu-item px-3" }
const _hoisted_9 = { class: "card-body pt-0 pb-0 p-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaymentTypeLabel = _resolveComponent("PaymentTypeLabel")!
  const _component_PaymentStatus = _resolveComponent("PaymentStatus")!
  const _component_DecodeId = _resolveComponent("DecodeId")!
  const _component_MenuLink = _resolveComponent("MenuLink")!
  const _component_InfoComponent = _resolveComponent("InfoComponent")!
  const _component_MenuItemInfo = _resolveComponent("MenuItemInfo")!
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_TagEntities = _resolveComponent("TagEntities")!
  const _component_Row = _resolveComponent("Row")!
  const _component_CurrencyFormat = _resolveComponent("CurrencyFormat")!
  const _directive_is_admin = _resolveDirective("is-admin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        (!_ctx.payment.group && _ctx.isChecker)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.payment.status !=='VOIDED')
                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                    key: 0,
                    class: "form-check-input",
                    type: "checkbox",
                    id: _ctx.payment.id,
                    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCheck && _ctx.onCheck(...args))),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.check) = $event))
                  }, null, 40, _hoisted_5)), [
                    [_vModelCheckbox, _ctx.check]
                  ])
                : _createCommentVNode("", true),
              _createElementVNode("label", {
                class: "form-check-label",
                for: _ctx.payment.id
              }, null, 8, _hoisted_6)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: "link-primary cursor-pointer hover",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadData(_ctx.payment.id,'PAYMENT')))
        }, [
          _createVNode(_component_PaymentTypeLabel, {
            type: _ctx.payment.type
          }, null, 8, ["type"]),
          _createTextVNode("  [" + _toDisplayString(_ctx.payment.subType) + "]   ", 1)
        ]),
        _createVNode(_component_PaymentStatus, {
          status: _ctx.payment.status
        }, null, 8, ["status"]),
        _withDirectives(_createVNode(_component_DecodeId, {
          id: _ctx.payment.id
        }, null, 8, ["id"]), [
          [_directive_is_admin]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_QuickAction, { size: "w-250px" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_MenuLink, {
                type: `CLICK`,
                title: "Edit",
                access: _ctx.payment?.access?.canEdit,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.edit(_ctx.payment)))
              }, null, 8, ["access"]),
              _createElementVNode("a", {
                href: "javascript:void(0);",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadData( {id:_ctx.payment.id, type:'PAYMENT'}, 'AUDITS'))),
                class: "menu-link px-3"
              }, " Change Log "),
              _createElementVNode("a", {
                href: "javascript:void(0);",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.loadData( {id:_ctx.payment.id, type:'PAYMENT'}, 'ACTION_VIEW'))),
                class: "menu-link px-3"
              }, " Action Log "),
              _createElementVNode("span", {
                class: "cursor-pointer menu-link px-3",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onDownloadEob(_ctx.payment.invoice, false)))
              }, "EOB "),
              _createElementVNode("span", {
                class: "cursor-pointer menu-link px-3",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onDownloadEob(_ctx.payment.invoice, true)))
              }, " EOB with Cheque"),
              _createVNode(_component_MenuLink, {
                type: `CLICK`,
                title: "Print Cheque",
                access: _ctx.payment.access.canPrintCheque,
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onDownloadCheque(_ctx.payment.id)))
              }, null, 8, ["access"]),
              _createVNode(_component_MenuLink, {
                type: `CLICK`,
                title: "Delete",
                access: _ctx.payment.access.canDelete,
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onDelete(_ctx.payment.id))),
                clazz: "text-danger"
              }, null, 8, ["access"]),
              _createVNode(_component_MenuItemInfo, null, {
                default: _withCtx(() => [
                  _createVNode(_component_MenuLink, {
                    type: `CLICK`,
                    title: "Void",
                    access: _ctx.payment.access.canVoid,
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onVoid(_ctx.payment.id))),
                    "is-item": false,
                    clazz: "text-danger"
                  }, null, 8, ["access"]),
                  _createVNode(_component_InfoComponent, {
                    tooltip: "Void",
                    "doc-id": "f399859f-13fc-487c-a377-f3f57c6bdc29"
                  })
                ]),
                _: 1
              }),
              _createElementVNode("a", {
                href: "javascript:void(0);",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.loadTagModal(_ctx.payment.id, 'PAYMENT'))),
                class: "menu-link px-3"
              }, " Tag ")
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_TagEntities, {
        entity: {objectId: _ctx.payment.id, objectType: 'PAYMENT'}
      }, null, 8, ["entity"]),
      _createVNode(_component_Row, {
        row: [{name:'Pay To', value: _ctx.payment.name}]
      }, null, 8, ["row"]),
      _createVNode(_component_Row, { row: [{name:'Address', slot:true, key:'address'}] }, {
        address: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.payment?.address?.fullAddress), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Row, {
        row: [{name:'Payment Method', value: _ctx.payment.paymentMethod},{name: 'Amount', slot: true, key: 'amount'}]
      }, {
        amount: _withCtx(() => [
          _createVNode(_component_CurrencyFormat, {
            currency: _ctx.payment.currency,
            amount: _ctx.payment.amount
          }, null, 8, ["currency", "amount"])
        ]),
        _: 1
      }, 8, ["row"]),
      (_ctx.payment.status ==='PAID')
        ? (_openBlock(), _createBlock(_component_Row, {
            key: 0,
            row: [{name:'Paid Date', value: _ctx.payment.paidDt, type:'DATE'}, {}]
          }, null, 8, ["row"]))
        : _createCommentVNode("", true),
      (_ctx.payment.parent)
        ? (_openBlock(), _createBlock(_component_Row, {
            key: 1,
            row: [{name: 'Group#', value:'', slot:true, key:'group'}, {}]
          }, {
            group: _withCtx(() => [
              _createElementVNode("span", {
                class: "link-primary cursor-pointer hover",
                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.loadData(_ctx.payment.parent.id, 'PAYMENT_GROUP')))
              }, _toDisplayString(_ctx.payment.parent.name), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}