
import {defineComponent, ref} from "vue";
import PaymentTypeLabel from "@/views/payment/PaymentTypeLabel.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import Row from "@/components/base/common/Row.vue";
import PaymentStatus from "@/views/payment/PaymentStatusLabel.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import {makeDownloadLink} from "@/core/helpers/functions";
import PaymentService from "@/core/services/PaymentService";
import DecodeId from "@/components/base/DecodeId.vue";
import {LoadPanel, LoadTagModal} from "@/core/composite/composite";
import InvoiceService from "@/core/services/InvoiceService";
import InfoComponent from "@/components/base/InfoComponent.vue";
import MenuLink from "@/components/base/action/MenuLink.vue";
import MenuItemInfo from "@/components/base/action/MenuItemInfo.vue";
import TagEntities from "@/views/tag/TagEntities.vue";

export default defineComponent({
  name: "PaymentCard",
  components: {
    TagEntities,
    MenuItemInfo,
    MenuLink, InfoComponent, DecodeId, QuickAction, PaymentStatus, Row, CurrencyFormat, PaymentTypeLabel
  },
  emits: ['delete', 'void', 'add', 'remove', 'edit'],
  props: {
    isChecker: {type: Boolean, default: () => false},
    payment: {type: Object, required: true}
  },
  setup(_, {emit}) {
    const check = ref(false);
    const edit = (payment) => {
      emit('edit', payment);
    }
    const onDelete = (id) => {
      emit('delete', id)
    }
    const onVoid = (id) => {
      emit('void', id);
    }
    return {
      edit,
      check,
      onVoid,
      onDelete,
      ...LoadPanel(),
      ...LoadTagModal(),
    }
  },
  methods: {
    onCheck() {
      if (this.check) {
        this.$emit('add', this.payment.id)
      } else {
        this.$emit('remove', this.payment.id)
      }
    },
    onDownloadCheque(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      PaymentService.printCheque(id).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
    onDownloadEob(id, cheque: boolean) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      InvoiceService.exportEOB(id, cheque).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
  }
})
