
import {LoadPanel} from "@/core/composite/composite";
import {defineComponent} from "vue";

export default defineComponent({
  name: "InfoComponent",
  props: {
    tooltip: {type: String, default: ''},
    docId: {type: String, default: ''},
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
