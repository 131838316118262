import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.docId)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: "javascript:void(0);",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadData(_ctx.docId, 'DOC')))
      }, [
        _createElementVNode("i", {
          class: "fas fa-exclamation-circle ms-2",
          "data-bs-toggle": "tooltip",
          title: _ctx.tooltip
        }, null, 8, _hoisted_1)
      ]))
    : (_openBlock(), _createElementBlock("i", {
        key: 1,
        class: "fas fa-exclamation-circle ms-2",
        "data-bs-toggle": "tooltip",
        title: _ctx.tooltip
      }, null, 8, _hoisted_2))
}